<template>
  <div
    class="field-table flex-1 flex flex-col max-h-full max-w-full min-h-full text-base-content overflow-y-auto scroll-bar"
  >
    <sub-header :showOnlyCustomCrumbs="true" :customCrumbLists="customBreadCrumbs"></sub-header>
    <div class="flex relative flex-1 flex-col flex-grow max-h-full max-w-full">
      <vue-good-table
        :isLoading="loading"
        :fixed-header="false"
        :columns="columns"
        :rows="filteredRowsData"
        :pagination-options="paginationOptions"
        v-bind="config"
        :totalRows="totalRows"
         @on-column-filter="onColumnFilter"
        @on-per-page-change="onPageChange($event, true)"
        @on-page-change="onPageChange($event)"
        @on-selected-rows-change="selectionChanged">

      >
        <template #column-filter="{ column }">
          <dropdown
                    :ref="setFilterRef"
                    reposition
                    searchable
                    class="filter-dropdown"
                    placeholder="Select"
                    v-if="column?.filterOptions && column?.filterOptions?.enabled"
                    :options="getFilterOptions(column)"
                    @search="searchFilter(column, $event)"
                    @input="(value) => updateColumnFilters(column, value)"
                    :value="filters[column.field]"
                    :limit="1"
                    maxWidth="100%"
                    width="100px"
                    minWidth="100%"
                    :config="{ label: 'name', trackBy: 'id' }"
                    :multiple="false"
                    :taggable="false"
                >
                    <template #caret>
                        <sort-icon class="fill-current" />
                    </template>
                </dropdown>
          <div class="" v-else>
            <!-- // adding a blank div to remove the default template   -->
          </div>
        </template>
        <template
          slot="table-row"
          slot-scope="props"
          v-show="props.row.type == 'source' || props.row.isnrnOpen"
        >
          <div
            class="flex gap-3 pl-3"
            v-if="props.column.field === 'credit_source_name'"
            :class="[props.row.system_default && 'default hover-text']"
          >
            <router-link
              :to="{
                name: 'companyDomain',
                params: { id: props.row.id },
                query: { credit_source_id: props.row.display_name },
              }"
              class="text-primary whitespace-nowrap cursor-pointer hover:text-dvbrandhoveron"
              >{{ props.row.display_name }}
            </router-link>
          </div>
          <div
            v-if="props.column.field === 'input'"
            class="nrnName"
            :title="props.row.input"
          >
            {{ getNrnName(props.row.input) }}
          </div>
          <div
            v-if="props.column.field === 'output'"
            class="nrnName"
            :title="props.row.output"
          >
            {{ getNrnName(props.row.output) }}
          </div>
          <div
            v-if="props.column.field === 'credits'"
            class="flex justify-evenly"
          >
            <span v-if="!props.row.isCreditPerHitEdit">{{ props.row.credit_per_hit }}</span>
            <input 
              v-else
              type="number"
              v-model="props.row.credit_per_hit" 
              id="props.row.id"
              class="input input-bordered input-xs w-16 text-center" 
              min="0"
              oninput="validity.valid||(value='')"
              @change="updateValues(props, 'credit_per_hit')"
            />
          </div>
          <div
            v-if="props.column.field === 'service_charge'"
            class="flex justify-evenly"
          >
          <span v-if="!props.row.isServiceChargeEdit">{{ props.row.service_charge_per_hit }}</span>
          <input 
            v-else
            type="text" 
            v-model="props.row.service_charge_per_hit" 
            class="input input-bordered input-xs w-16 text-center" 
            @input="updateValues(props, 'credit_per_hit')"
          />
          </div>
          <div
            v-if="props.column.field == 'tenants'"
            class="flex justify-center"
          >
            <div
              class="indicator cursor-pointer"
              @click="assignedTenants(props.row.custom_tenants)"
            >
              <span class="w-2 h-4 indicator-item badge badge-primary">{{
                props.row.custom_tenants.length
              }}</span>
              <font-awesome-icon class="w-6 mx-2" icon="user" />
            </div>
          </div>
          <div
            v-if="props.column.field == 'clients'"
            class="flex justify-center"
          >
            <div
              class="indicator cursor-pointer"
              @click="assignedClients(props.row.custom_clients)"
            >
              <span class="w-2 h-4 indicator-item badge badge-primary">{{
                props.row.custom_clients.length
              }}</span>
              <font-awesome-icon class="w-6 mx-2" icon="user" />
            </div>
          </div>
          <div
            v-if="props.column.field == 'actions'"
            class="flex justify-center items-center"
            >  
            <div @click="editSource(props)" class="h-8 w-8 flex items-center justify-center rounded-half duration-150 CreditSourceEditIcon" :class="{'isEditClieked' : props.row.isServiceChargeEdit}">
              <svg data-v-977f11e4="" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="edit" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" class="cursor-pointer text-sm fill-current svg-inline--fa fa-edit" style="opacity: 1; cursor: pointer;">
                <path data-v-977f11e4="" fill="currentColor" d="M402.6 83.2l90.2 90.2c3.8 3.8 3.8 10 0 13.8L274.4 405.6l-92.8 10.3c-12.4 1.4-22.9-9.1-21.5-21.5l10.3-92.8L388.8 83.2c3.8-3.8 10-3.8 13.8 0zm162-22.9l-48.8-48.8c-15.2-15.2-39.9-15.2-55.2 0l-35.4 35.4c-3.8 3.8-3.8 10 0 13.8l90.2 90.2c3.8 3.8 10 3.8 13.8 0l35.4-35.4c15.2-15.3 15.2-40 0-55.2zM384 346.2V448H64V128h229.8c3.2 0 6.2-1.3 8.5-3.5l40-40c7.6-7.6 2.2-20.5-8.5-20.5H48C21.5 64 0 85.5 0 112v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V306.2c0-10.7-12.9-16-20.5-8.5l-40 40c-2.2 2.3-3.5 5.3-3.5 8.5z" class="">
                </path>
              </svg>
            </div>
            <button @click="updateSource(props)" v-if="props.row.isCreditPerHitEdit || props.row.isServiceChargeEdit" class="btn normal-case px-4 ml-3 leading-tight flex items-center rounded-xl btn-success btn-xs h-7">
              <span class="capitalize font-bold tracking-wide">save</span></button>
          </div>
        </template>
        <template #loadingContent>
          <div class="w-full py-10 justify-center flex">
            <Loader />
          </div>
        </template>
      </vue-good-table>
      <button
        v-if="Object.values(filters).length"
        class="cursor-pointer py-1 px-3 bg-gray-100 hover:bg-gray-200 rounded-md text-xs text-black flex justify-center items-center font-semibold absolute z-50 left-2"
        :class="isSelectionChanged ? 'top-14' : 'top-2'"
        @click="clearFilters"
      >
        Clear filters
      </button>
    </div>
    <showTenantClientModal
      ref="assignedListModal"
      :title="currentModalTitle"
      :modalData="modalData"
    />
  </div>
</template>

<script>
import { getSourceList, updateSourceDetails } from "./services";
import SubHeader from "@/components/SubHeader";
import showTenantClientModal from "./tenantClientModal.vue";
import Loader from "@shared/loader";
// import Modal from "@/components/modal-content"
import dropdown from "@shared/components/dropdown-base";
import { onlyUnique } from "@shared/plugins/utils.js";
import { uuid } from "vue-uuid";
import {FormatDateNew} from "@/plugins/functions";
// import { DateTime } from "luxon";
// import DatePicker from "vue2-datepicker";
// import sortIcon from "@shared/assets/svg/dropdown-double.svg";
// import { format, parse } from "date-fns";

export default {
  data: function () {
    return {
      customBreadCrumbs: [{ name: "Admin" }, { name: "Feature" }],
      columns: [
        {
          label: "Feature",
          field: "credit_source_name",
          filter_type: "credit_source_name",
          sortable: true,
          filterOptions: {
            enabled: true, // enable filter for this column
            multi: true,
            isLoading: false,
            query_key: "credit_source_name",
          },
        },
        {
          label: "Input",
          field: "input",
          sortable: true,
          // filterOptions: {
          //   enabled: true, // enable filter for this column
          //   multi: true,
          //   isLoading: false,
          //   query_key: "sub_feature_name",
          // },
        },
        {
          label: "Output",
          field: "output",
        },
        {
          label: "Credits/Hits",
          field: "credits",
        },
        {
          label: "Custom Tenant",
          field: "tenants",
          filter_type: "tenant_users",
          sortable: true,
          filterOptions: {
            enabled: true, // enable filter for this column
            multi: true,
            isLoading: false,
            query_key: "tenants",
          },
        },
        {
          label: "Custom Client",
          field: "clients",
          filter_type: "client_users",
          sortable: true,
          filterOptions: {
            enabled: true, // enable filter for this column
            multi: true,
            isLoading: false,
            query_key: "clients",
          },
        },
        {
          label: "Actions",
          field: "actions",
        },
      ],
      params: {
        page: 1,
        limit: 10,
        cache_bypass: true,
      },
      totalRows: 0,
      filters: {},
      serverParams: {},
      searchLoading: {},
      date_range: [],
      filterRefs: [],
      filterOptionsAsync: {},
      filterOptions: {},
      isSelectionChanged: false,
      rows: [],
      filteredRowsData: [],
      loading: false,
      isSourceLoading: false,
      showAssignedModal: false,
      currentModalTitle: "",
      modalData: [],
      allSourceData: [],
      paginationOptions: {
                enabled: true,
                mode: "records",
                infoFn: (params) => `${params.firstRecordOnPage} - ${params.lastRecordOnPage} of ${params.totalRecords}`,
                perPageDropdown: [10, 20, 30, 40, 50],
                dropdownAllowAll: false,
      },
    };
  },
  components: {
    SubHeader,
    Loader,
    // DatePicker,
    showTenantClientModal,
    dropdown,
  },
  async mounted() {
    this.loading = true;
    await this.setSourceList();
    this.filterOptions = this.columnFilterOptions;
    this.filteredRowsData = this.rows;
    this.loading = false;
  },
  computed: {
    isFilters() {
      return this.serverParams && Object.entries(this.serverParams).length;
    },
    columnFilterOptions() {
      return this.getFilterOptions(this.columnOption, this.rows);
    },
    columnOption() {
      return this.columns.map((el) => ({
        ...el,
        config: {
          ...el.config,
          isLoading: !!this.searchLoading[el.field] || false,
        },
      }));
    },
    computedColumns() {
      // use option list from server if it is:
      const options = this.filterOptions;
      // had to check for empty object
      if (options && Object.entries(options).length) {
        return this.columns.map((col) => {
          const filtered = col.config?.filter ?? true;
          if (col.prefetchedOptions && col.prefetchedOptions.length) {
            return {
              ...col,
              filterOptions: {
                enabled: filtered,
                filterDropdownItems: col.prefetchedOptions,
              },
            };
          } else {
            return {
              ...col,
              filterOptions: {
                enabled: filtered,
                filterDropdownItems: options[col.field],
              },
            };
          }
        });
      } else {
        // TODO remove/rework default filters
        return this.columns.map((col) => {
          const filtered = col.config?.filter ?? true;
          return {
            ...col,
            filterOptions: filtered
              ? {
                  enabled: true,
                  placeholder: "All",
                  filterDropdownItems: this.rows
                    .filter((row) => row[col.field])
                    .map((row) => ({
                      id: row.field_type_id ?? uuid.v4(),
                      name: row[col.field],
                    }))
                    .filter(onlyUnique),
                }
              : undefined,
          };
        });
      }
    },
  },
  methods: {
    async setSourceList() {
      this.isSourceLoading = true;
      const filterParams = this.makeFilterParams();
      let qParams;
      qParams = new URLSearchParams(this.params);
      if (filterParams) {
        qParams = new URLSearchParams({ ...this.params, ...filterParams });
      }
      const { data } = await getSourceList(qParams);
      this.isSourceLoading = true;
      this.rows = data.data;
      this.rows = this.rows?.map(el => {
        el.isCreditPerHitEdit = false,
        el.isServiceChargeEdit = false
        return el;
      })
      this.filteredRowsData = this.rows;
      this.totalRows = this.filteredRowsData.length;
    },
    getNrnName(value) {
      if (value?.length > 25) {
        return value.substring(0, 27) + "...";
      } else {
        return value;
      }
    },
    editSource(sourceData) {
      this.filteredRowsData = this.filteredRowsData?.map(el => {
        if (el?.id == sourceData.row.id) {
          el.isCreditPerHitEdit = !sourceData.row.isCreditPerHitEdit
          el.isServiceChargeEdit = !sourceData.row.isServiceChargeEdit
        }
        return el;
      })
      console.log(this.filteredRowsData)
    },
    updateValues(sourceData, key) {
      this.filteredRowsData = this.filteredRowsData.map(rowData => {
        if(sourceData?.row?.id == rowData.id) {
          rowData[key] = sourceData?.row[key]
        }
        return rowData;
      })
    },
    async updateSource(sourceData) {
      let payload ={
        id: sourceData?.row?.id,
        input: sourceData?.row?.input ? sourceData.row.input : "",
        output: sourceData?.row?.output ? sourceData.row.output : "",
        display_name:  sourceData?.row?.display_name,
        credit_per_hit: parseInt(sourceData?.row?.credit_per_hit),
        service_charge_per_hit: parseInt(sourceData?.row?.service_charge_per_hit),
      } 
      if(sourceData?.row?.credit_per_hit != '') {
        await updateSourceDetails(payload);
        this.$toast.success('Source Update Successfully');
        await this.setSourceList();
      }
      else {
        this.$toast.error("Please fill all required fields");
      }
    },          
    async assignedTenants(data) {
      this.modalData = data;
      this.currentModalTitle = "Tenants";
      if (this.$refs.assignedListModal.showModal()) {
        this.$refs.assignedListModal.showModal();
      }
    },
    async assignedClients(data) {
      this.modalData = data;
      this.currentModalTitle = "Clients";
      if (this.$refs.assignedListModal.showModal()) {
        this.$refs.assignedListModal.showModal();
      }
    },
    makeFilterParams() {
      let filterParams = {};
      if (this.serverParams) {
        Object.keys(this.serverParams).map((key) => {
          filterParams[key] = this.serverParams[key]
            ? this.serverParams[key]["name"]
            : delete filterParams[key];
          if (this.serverParams[key]["name"] === undefined) {
            delete filterParams[key];
          }
          if (key.includes("date_from") || key.includes("date_to")) {
            filterParams[key] = this.formatDate(filterParams[key]);
          }
        });
      }
      return filterParams;
    },
    getFilterOptions(column){
      // get unique filter options array
      let filterData = []
      
      if (column?.filter_type == 'client_users' && this.rows?.length > 0) {
          this.rows.forEach((rowfilter) => {
              rowfilter?.custom_clients?.map((rowData) => {
                  if (!filterData?.some?.(data => data?.name == rowData?.client_name)) {
                      filterData?.push(
                          {
                              id: rowData?.client_id,
                              name: rowData?.client_name
                          }
                          )
                      }
              })
          })
      } else if (column?.filter_type == 'tenant_users' && this.rows?.length > 0) {
          this.rows.forEach((rowfilter) => {
              rowfilter?.custom_tenants?.map((rowData) => {
                  if (!filterData?.some?.(data => data?.name == rowData?.tenant_name)) {
                      filterData?.push(
                          {
                              id: rowData?.tenant_id,
                              name: rowData?.tenant_name
                          }
                          )
                      }
              })
          })
      } else {
          filterData = this.rows
          .filter((row) => row[column.field])
          .map((row) => (
              { 
                  id: row.field_type_id ?? uuid.v4(), 
                  label: row[column.field],
                  name: column?.filter_type == 'date' ? FormatDateNew(new Date (row[column.field])) : row[column?.field]
              }
          )
          )
          if (filterData && filterData?.length > 0) {
              const uniqueMap = new Map();
              filterData = filterData.forEach(item => {
                uniqueMap.set(item.name, item);
              });
              return Array.from(uniqueMap.values());
          }
      }
      return filterData;
    },
    updateColumnFilters(column, value){
        if (column?.filter_type == 'date') {
            this.filters[column.field]  =  value.label ? [value.label] : [] ;
        } else {
            this.filters[column.field]  =  value.name ? [value.name] : [] ;
        }
        let result = this.rows;
        for (let filter in this.filters) {
            if (this.filters[filter]?.length) {
                if (column?.filter_type == 'client_users') {
                    result = result?.filter((el) => el?.custom_clients?.some(clnt => clnt?.client_name == value?.name))
                } else if (column?.filter_type == 'tenant_users') {
                    result = result?.filter((el) => el?.custom_tenants?.some(tnt => tnt?.tenant_name == value?.name))
                } else {
                    result = result.filter((el) => this.filters[filter].includes(el[filter]));
                }
            }
        }
        this.filteredRowsData = result;
        this.totalRows = this.filteredRowsData.length;
    },
    async clearFilters() {
      // this.serverParams = null;
      this.filters = {};
      this.filteredRowsData = this.rows;
      this.totalRows = this.filteredRowsData.length;
      // await this.setSourceList();
    },
    async onColumnFilter({ columnFilters }) {
      this.params.page = 1;
      this.serverParams = columnFilters ? { ...columnFilters } : null;
      await this.setSourceList();
    },
    selectionChanged(e) {
      // console.log('e :>> ', e);
      if (e) {
        this.isSelectionChanged = e.selectedRows && e.selectedRows.length !== 0;
        this.$emit("on-selected-rows-change", e.selectedRows);
      }
    },
    async onPageChange(data, resetPage = false) {
      if (resetPage) {
        this.params.page = 1;
      } else this.params.page = data.currentPage;
      this.params.limit = data.currentPerPage;
      await this.setSourceList();
    },
    searchFilter(column, value) {
      this.$emit("search-filter", { column, value });
    },
    setFilterRef(el) {
      if (el) {
        this.filterRefs.push(el);
      }
    },
    getFilterValue(col) {
      // isFilters ? filters[column.field] : null
      if (this.isFilters) {
        return this.serverParams[col.field] ?? this.serverParams[col.query_key];
      } else return null;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@shared/assets/mixins/mixins.scss";
@include tableComponent;

.openNrns {
  display: flex;
  padding: 1px 2px;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  cursor: pointer;
}

.CreditSourceEditIcon {
  &:hover {
    background: #407fc8;
    color: #fff;
  }
}

.isEditClieked {
  background: #407fc8;
  color: #fff;
}

.flex-column {
  flex-direction: column;
}

.nrnList {
  padding: 10px;
  border-bottom: 2px solid lightgray;
  border-top: 2px solid lightgray;
  background: white;
}

.nrnName {
  width: max-content;
  word-break: break-word;
}
</style>
